import React from 'react'
import Title from '../../Components/Title/Title'
import styles from './Study.module.css'
import {
  studyFirst,
  studyIcon,
  studySecond,
} from '../../Assets/Images/Study/Study'
const Study = () => {
  const courseInfoData = [
    {
      title: '2 блоки теорії в тиждень',
      description:
        'Уроки відкриваються в Вашому особистому кабінеті. Доступ до них залишається протягом 1 року.',
    },
    {
      title: 'Час',
      description:
        'Вам знадобиться близько 4 годин в тиждень на засвоєння матеріалів і виконання домашніх завдань. Постійно бути онлайн не потрібно - Ви можете задати питання або виконати домашнє завдання в будь-який зручний час.',
    },
    {
      title: 'Канал в телеграм',
      description:
        'В каналі публікуються анонси, розклад занять, повідомлення щодо відкриття нових уроків, загрузки записів ефірів, посилання на додаткові матеріали.',
    },
    {
      title: '1 прямий ефір в тиждень',
      description:
        'Інструкція про те, як підключатися, а також технічна допомога кураторів гарантують Вам відсутність проблем з ефірами. Навіть якщо Ви ніколи не працювали з ZOOM.',
    },
    {
      title: 'Старт навчання',
      description: 'Тривалість навчання: 1 або 2 місяці.',
    },
    {
      title: 'Чат в телеграм',
      description:
        'В чаті Ви можете задати будь-яке питання, Тарас і куратори відповідають на Ваші питання кожного дня з 10 ранку до 22 вечора по Києву протягом всього курсу.',
    },
  ]
  return (
    <div className={styles.study}>
      <img src={studyFirst} alt="icon" className={styles.first} />
      <img src={studySecond} alt="icon" className={styles.second} />
      <Title
        classTitle={styles.study_title}
        second={'Як відбувається'}
        third={'Процес навчання'}
      />
      <div className={`${'customContainer'} ${styles.wrap}`}>
        {courseInfoData.map((item, index) => (
          <div key={index} className={styles.wrap_item}>
            <img
              src={studyIcon}
              alt="check icon"
              className={styles.wrap_icon}
            />
            <div>
              <h3 className={styles.wrap_title}>{item.title}</h3>
              <p className={styles.wrap_description}>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default Study
