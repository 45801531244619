import React, { useState } from 'react'
import Title from '../../Components/Title/Title'
import './faq.css'
import { Accordion } from 'react-bootstrap'
import { Close, More } from '../../Assets/Icons/Faq'

const Faq = () => {
  const [expanded, setExpanded] = useState(false)

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }
  return (
    <div className="customContainer faq_block">
      <div className="faq_title">
        <Title first={'Відповіді'} second={'на часті запитання'} third={''} />
      </div>
      <div className="customContainerFaq">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Які знання я повинен(-на) мати для проходження курсу?
            </Accordion.Header>
            <Accordion.Body>
              Для освоєння курсу “Інвестиції в акції з нуля” вам достатньо мати
              мінімальні навички роботи з тим пристроєм, з якого збираєтеся
              проходити курс - телефону, планшета або комп'ютера.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>
              Чи підійде мені курс, якщо я не маю жодних знань про інвестиції?
            </Accordion.Header>
            <Accordion.Body>
              Так, звісно! Курс “Інвестиції в акції з нуля”- це ідеальний
              варіант для системного вивчення теми з 0 до повного розуміння теми
              та складання стратегії та портфеля.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Чи буде мені корисний курс, якщо я вже маю знання про інвестування
              і навіть зібраний портфель?
            </Accordion.Header>
            <Accordion.Body>
              Безумовно так! Курс - система знань та практичних навичок, які
              дозволять вам розібратися в тонкощах, покращити наявний портфель
              та відкоригувати стратегію.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>
              Чи можна проходити курс з мобільного пристрою?
            </Accordion.Header>
            <Accordion.Body>
              Курс адаптований для всіх сучасних пристроїв, тому ви можете
              проходити курс як з телефону та планшета, так і з комп'ютера.
              Останнє завдання – складання портфеля – буде зручніше виконувати з
              комп'ютера, але третина наших учнів виконує всі завдання повністю
              з телефону.
            </Accordion.Body>
          </Accordion.Item>
          {expanded && (
            <>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Як я можу поставити запитання, якщо мені щось буде
                  незрозуміло?
                </Accordion.Header>
                <Accordion.Body>
                  Ви можете поставити своє питання в чаті курсу - 12 годин на
                  день я та куратори відповідаємо на ваші запитання.
                  Індивідуальні питання щодо домашніх завдань можна поставити в
                  робочому зошиті - під час перевірки ви обов'язково отримаєте
                  зворотний зв'язок. Якщо питання велике - сміливо ставте його
                  на ефірі. На курсі проходять ефіри щотижня зі мною, щоб точно
                  відповісти на всі можливі питання!
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  Що робити, якщо хочу особисту консультацію/персональне
                  складання портфеля?
                </Accordion.Header>
                <Accordion.Body>
                  На VIP-пакеті у нас відбувається повністю індивідуальна
                  робота: проведення фінансового аналізу, приватна розробка
                  Вашого фінансового плану та річний супровід.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6">
                <Accordion.Header>Чи є підтримка після курсу?</Accordion.Header>
                <Accordion.Body>
                  Так, це одна з особливостей мого курсу. Після успішного
                  проходження курсу та складання підсумкового тестування, ви
                  потрапляєте до закритого клубу Випускників. Там я викладаю
                  оновлені матеріали курсу – ефіри з ідеями з ринку, розбори
                  тестів, а також проводжу спеціальні конференції
                  ПИТАННЯ-ВІДПОВІДІ.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  Чи підійде мені курс, якщо я проживаю не в Україні?
                </Accordion.Header>
                <Accordion.Body>
                  Основна інформація курсу – універсальна, принципи інвестування
                  однакові для всіх країн. ⅞ блоків із програми навчання вам
                  підходять! Самостійно доведеться вивчити тему оподаткування
                  вашої країни та підібрати брокерів за критеріями, описаними в
                  курсі.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  Доступ до матеріалів залишається назавжди?
                </Accordion.Header>
                <Accordion.Body>
                  Ваш робочий зошит та всі роздаткові матеріали залишаються у
                  вас назавжди. Доступ до особистого кабінету: теорії та записів
                  ефірів залишається у вас щонайменше на 1 рік. Ми не збираємося
                  видаляти доступ через рік, але залишаємо за собою це право,
                  якщо ми закінчимо місце на сервері.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  Яка сума потрібна для того, щоб почати інвестувати?
                </Accordion.Header>
                <Accordion.Body>
                  З комфортних вам сум. Для покупки першої акції досить 100
                  доларів, оптимальною сумою, мій погляд, є 1000 доларів.
                  Головне при невеликих розмірах початкового капіталу -
                  регулярність поповнень.
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="10">
                <Accordion.Header>
                  Я оплатив(-а) курс. Що далі?
                </Accordion.Header>
                <Accordion.Body>
                  За 2-4 дні до старту курсу надходить розсилка з усіма
                  важливими посиланнями та інструкціями. Обов'язково слідкуйте
                  за анонсами в моєму інстаграмі і перевіряйте спам-іноді
                  розсилки можуть туди потрапити. Якщо в день початку курсу ви
                  так і не отримали розсилку – пишіть на пошту
                  radzivil.ovb@gmail.com
                </Accordion.Body>
              </Accordion.Item>
            </>
          )}
        </Accordion>
        <div className="more_btn_block">
          <button
            className="more_btn"
            variant="primary"
            onClick={toggleExpanded}
            style={{ marginTop: '1rem' }}
          >
            {expanded ? (
              <img className="more_img" src={Close} alt="Згорнути всі" />
            ) : (
              <img className="more_img" src={More} alt="Розгорнути всі" />
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Faq
