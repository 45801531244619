import React from 'react'
import './numbers.css'

const Numbers = () => {
  return (
    <div className="numbers_block">
      <div className="customContainer numbers_content">
        <div className="numbers_info">
          <h2 className="numbers_number">8</h2>
          <p className="numbers_text">
            тижнів зворотнього <br /> зв’язку
          </p>
        </div>
        <div className="numbers_info">
          <h2 className="numbers_number">38+</h2>
          <p className="numbers_text">годин матеріалу</p>
        </div>
        <div className="numbers_info">
          <h2 className="numbers_number">8</h2>
          <p className="numbers_text">живих ефірів</p>
        </div>
      </div>
    </div>
  )
}

export default Numbers
