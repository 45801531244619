import React from 'react'
import styles from './Author.module.css'
import Title from '../../Components/Title/Title'
import {
  author,
  author1,
  author2,
  author3,
} from '../../Assets/Images/Author/Author'
const Author = () => {
  const authorData = {
    name: 'ТАРАС РАДЗІВІЛ',
    role: 'АВТОР І ВЕДУЧИЙ КУРСУ',
    details: [
      'сертифікований тьютор по фінансовій грамотності на інвестуванню від IAPBE (Альянс Професійальних Бізнес-еліт, Лондон, Великобританія)',
      'сертифікований незалежний фінансовий радник з 2009 року (OVB VermogensBeratung A.G., Кельн, Німеччина)',
      'навчив 2000+ людей інвестуванню на фондовому ринку',
      'автор книги “Із боржника в інвестори” та 7 брошур на фінансову тематику',
      'понад 10000 годин безпосередньої роботи з клієнтами',
      '500+ розроблених особистих фінансових планів',
      'роздрібний інвестор.',
    ],
  }

  return (
    <div className={styles.author}>
      <div className={`${'customContainer'} ${styles.author_wrap}`}>
        <Title
          first={'Автор'}
          second={'Курсу'}
          classTitle={styles.author_title}
        />
        <div className={styles.author_wrapper}>
          <div className={styles.author_wrapper__item}>
            <h2 className={styles.author_wrapper__title}>{authorData.name}</h2>
            <p className={styles.author_wrapper__role}>{authorData.role}</p>
            <ul className={styles.author_list}>
              {authorData.details.map((detail, index) => (
                <li key={index} className={styles.author_list__item}>
                  <svg
                    className={styles.author_list__item_mark}
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                  >
                    <circle
                      cx="3.901"
                      cy="3.74683"
                      r="3.56714"
                      fill="url(#paint0_linear_22_7)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_22_7"
                        x1="7.46814"
                        y1="8.50299"
                        x2="0.333862"
                        y2="3.74683"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#007EC6" />
                        <stop offset="1" stop-color="#5CC4FF" />
                      </linearGradient>
                    </defs>
                  </svg>
                  {detail}
                </li>
              ))}
            </ul>
          </div>
          <img
            className={styles.author_wrapper__img}
            src={author}
            alt="Taras Radzivil"
          />
        </div>
      </div>
      <img className={styles.author_img1} src={author1} alt="icon" />
      <img className={styles.author_img2} src={author2} alt="icon" />
      <img className={styles.author_img3} src={author3} alt="icon" />
    </div>
  )
}

export default Author
