import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { FormImg } from '../../Assets/Images/Form/Form'
import styles from './Form.module.css'
import { baseURL, chatId, validator } from '../../Constants/urls'
import Title from '../../Components/Title/Title'

const Form = () => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm()

  const onSubmit = async (data) => {
    const { name, phone } = data
    let msg = `Заявка на консультацію:\nІм'я - ${name}\nТелефон - ${phone}`

    if (name && phone) {
      try {
        const response = await fetch(baseURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            validator: validator,
            chat_id: chatId,
            message: msg,
          }),
        })

        if (!response.ok) {
          throw new Error('Network response was not ok')
        }

        reset()
        setValue('phone', '')
        setIsSubmitted(true)
        setTimeout(() => setIsSubmitted(false), 3000) // Reset the button after 3 seconds
      } catch (error) {
        console.error('Помилка при виконанні запиту:', error)
      }
    }
  }

  return (
    <div className="customContainer" id="form">
      <Title
        first={'Не можете'}
        second={'Визначитись'}
        classTitle={styles.form_title}
      />
      <div className={styles.formContainer}>
        <img src={FormImg} alt="icon" className={styles.icon} />
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <p className={styles.form_description}>
            Залишіть свій контакт і ми підберемо тариф індивідуально під вас
          </p>
          <input
            {...register('name', { required: true })}
            placeholder="Ім'я"
            className={styles.form_input}
          />
          {errors.name && (
            <span className={styles.form_input__error}>
              Це поле обов'язкове!
            </span>
          )}
          <InputMask
            mask="+380(99)999-9999"
            maskChar=" "
            {...register('phone', { required: true })}
            className={styles.form_input}
            placeholder="Телефон"
          />
          {errors.phone && (
            <span className={styles.form_input__error}>
              Це поле обов'язкове!
            </span>
          )}
          <button type="submit" className={styles.form_button}>
            {isSubmitted ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="white"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm-1.75 15.25L4.5 11l1.41-1.41L8.25 12.34l5.84-5.84L15.5 8.5l-7.25 6.75z"
                  fill="white"
                />
              </svg>
            ) : (
              'ЗАПИСАТИСЬ'
            )}
          </button>
        </form>
      </div>
      <div className={styles.developed}>
        <span className={styles.developed_title}>Developed by</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
        >
          <path
            d="M19.7088 6.11042C17.5594 1.02357 11.6935 -1.35608 6.6085 0.793206C1.52157 2.94258 -0.858066 8.80835 1.2913 13.8935C3.44067 18.9803 9.30643 21.36 14.3915 19.2107C19.4784 17.0613 21.858 11.1974 19.7088 6.11042ZM6.31463 4.14645L14.3878 3.65591L15.352 4.85231L9.2821 9.80628L9.13417 7.35736L10.9671 5.79213L6.43067 6.06735L6.31463 4.14645ZM10.7219 16.8535C7.04481 17.0763 5.13508 14.2848 4.59769 13.7287L6.36517 12.4462C6.90246 13.0003 7.99778 14.9344 10.6002 14.8465C12.9443 14.7678 13.9497 12.9929 13.8729 11.7384C13.8392 11.173 13.4984 10.2949 12.2665 9.95799L13.9122 8.61177C15.5654 9.34195 15.9998 10.8585 16.0503 11.6898C16.172 13.6987 14.8165 16.6045 10.7219 16.8535Z"
            fill="#442CE9"
          />
        </svg>
        <span className={styles.developed_text}>Znaesh Agency</span>
      </div>
    </div>
  )
}

export default Form
