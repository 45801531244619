import React from 'react'
import styles from './WillGet.module.css'
import Title from '../../Components/Title/Title'
import {
  icon1,
  icon2,
  icon3,
  icon4,
  WillGet1,
  WillGet2,
} from '../../Assets/Images/WillGet/WillGet'

const WillGet = () => {
  const items = [
    {
      icon: icon1,
      text: {
        black: 'купите свою першу акцію на',
        blue: 'фондовому ринку США чи ЄС',
      },
    },
    {
      icon: icon2,
      text: {
        black: 'навчитеся підбирати акції,',
        blue: 'в які слід інвестувати',
      },
    },
    {
      icon: icon3,
      text: {
        black: 'створите свій власний',
        blue: 'інвестиційний портфель',
      },
    },
    {
      icon: icon4,
      text: {
        black: 'почнете отримувати:',
        blue: 'пасивний дохід',
      },
    },
  ]

  return (
    <div className={styles.willGet}>
      <img className={styles.willGet_img1} src={WillGet1} alt="Icon" />
      <img className={styles.willGet_img2} src={WillGet2} alt="Icon" />
      <Title
        classTitle={`${styles.willGet_title}`}
        first={'Що ви отримаєте'}
        second={'В результаті прозодження курсу'}
        third={''}
      />
      <div className={`${'customContainer'} ${styles.willGet_wrap}`}>
        {items.map((item, index) => (
          <div key={index} className={styles.willGet_item}>
            <img src={item.icon} alt="" className={styles.willGet_icon} />
            <p>
              <span className={styles.willGet_blackText}>
                {item.text.black}{' '}
              </span>
              <br />
              <span className={styles.willGet_blueText}>{item.text.blue}</span>
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default WillGet
