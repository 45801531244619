import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './Reviews.module.sass';
import {
    Review1,
    Review2,
    Review3,
    Review4,
    Review5,
    Review6,
    Review7,
    Review8,
    Review9,
    Review10,
    Review11,
    Review12
} from "../../Assets/Images/Reviews/Reviews";
import Title from "../../Components/Title/Title";

// Компоненти для стрілок
const PrevArrow = ({onClick}) => (
    <svg className={`${styles.slider_button} ${styles.prevButton}`} onClick={onClick} xmlns="http://www.w3.org/2000/svg"
         width="31" height="41" viewBox="0 0 31 41" fill="none">
        <path d="M27.9152 37.377L5.74131 20.3059L27.9152 3.2348" stroke="#007EC6" stroke-width="7"/>
    </svg>
);

const NextArrow = ({onClick}) => (
    <svg className={`${styles.slider_button} ${styles.nextButton}`} onClick={onClick} xmlns="http://www.w3.org/2000/svg"
         width="31" height="41" viewBox="0 0 31 41" fill="none">
        <path d="M3.08475 3.23438L25.2586 20.3055L3.08475 37.3765" stroke="#007EC6" stroke-width="7"/>
    </svg>
);

const Reviews = () => {
    const images = [
        Review1,
        Review2,
        Review3,
        Review4,
        Review5,
        Review6,
        Review7,
        Review8,
        Review9,
        Review10,
        Review11,
        Review12
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: <PrevArrow/>,
        nextArrow: <NextArrow/>,
        responsive: [
            {
                breakpoint: 720, // Ширина, при якій застосовуються ці налаштування
                settings: {
                    slidesToShow: 1, // Показуємо лише 1 слайд при ширині до 720px
                },
            },
        ],
    };

    return (
        <div className={styles.sliderContainer}>
            <Title first={'Відгуки'} second={'Учасників'} third={''}/>
            <div className={styles.slider}>
                <Slider {...settings} >
                    {images.map((src, index) => (
                        <div key={index} className={styles.slider_item}>
                            <img src={src} alt={`Slide ${index}`} className={styles.slider_image}/>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default Reviews;
