import React from 'react'
import {
  At,
  Bank,
  Cola,
  Ford,
  Hewlet,
  Hp,
  Pfizer,
  Warner,
} from '../../Assets/Images/Companies'
import './companies.css'

const Companies = () => {
  return (
    <div className="customContainer companies_block">
      <div>
        <h1 className="companies_title">Кожен учасник курсу отримає</h1>
        <h1 className="companies_title companies_title_span">
          в подарунок одну акцію
        </h1>
        <h1 className="companies_title">наступних компаній</h1>
      </div>
      <div className="companies_images">
        <div>
          <img className="cola" src={Cola} alt="Cola" />
        </div>
        <div>
          <img className="bank" src={Bank} alt="Bank" />
        </div>
        <div>
          <img className="pfizer" src={Pfizer} alt="Pfizer" />
        </div>
        <div>
          <img className="hp" src={Hp} alt="Hp" />
        </div>
        <div>
          <img className="hewlet" src={Hewlet} alt="Hewlet" />
        </div>
        <div>
          <img className="ford" src={Ford} alt="Ford" />
        </div>
        <div>
          <img className="at" src={At} alt="At" />
        </div>
        <div>
          <img className="warner" src={Warner} alt="Warner" />
        </div>
      </div>
      <div>
        <p className="companies_text">
          при реєстрації брокерського рахунку і поповненні його від 100 дол
        </p>
      </div>
    </div>
  )
}

export default Companies
