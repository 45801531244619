import React from 'react'
import styles from './Title.module.css'
const Title = ({ first, second, third, classTitle }) => {
  return (
    <h2 className={`${styles.title} ${classTitle}`}>
      {first} <span className={styles.title_mod}>{second}</span>
      <br />
      {third}
    </h2>
  )
}

export default Title
