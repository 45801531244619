import React from 'react'
import Title from '../../Components/Title/Title'
import './waiting.css'
import { WaitingLeft, WaitingRight } from '../../Assets/Images/Waiting'

const data = [
  { number: 1, text: 'Доступ до ', spanText: 'матеріалів протягом 1 року' },
  {
    number: 2,
    text: '2 блоки теорії в тиждень (в одному блоці може бути кілька відео: ',
    spanText: 'практика, розбір ДЗ, приватні випадки)',
  },
  {
    number: 3,
    text: 'Щотижневі ефіри по пройденій теорії ',
    spanText: 'для закріплення матеріалу',
  },
  {
    number: 4,
    text: 'Домашні практичні завдання, котрі дозволять крок за кроком ',
    spanText: 'освоїти матеріал і застосувати його на собі',
  },
  {
    number: 5,
    text: 'Зворотній зв’язок і мотивація в чаті від кураторів ',
    spanText: 'кожний день, 12 годин в день ',
  },
  {
    number: 6,
    text: 'Індивідуальна робота  ',
    spanText: 'з кожним учнем',
  },
  {
    number: 7,
    text: 'Унікальний портфель, складений самостійно учнем, ',
    spanText: 'виходячи з його індивідуальний потреб',
  },
  {
    number: 8,
    text: 'Додаткові матеріали ',
    spanText: '(дайджест акцій США, фонди, додаткові матеріали до курсу).',
  },
]

const Waiting = () => {
  return (
    <div className="waiting_block">
      <div className="customContainer waiting_tablet ">
        <img
          className="waiting_img_right"
          src={WaitingRight}
          alt="WaitingRight"
        />
        <img className="waiting_img_left" src={WaitingLeft} alt="WaitingLeft" />
        <div className="waiting_title">
          <Title first={'Що вас чекає '} second={'на курсі'} third={''} />
        </div>
        <div className="waiting_info_list">
          {data.map((item, index) => (
            <div key={index} className="waiting_info_block">
              <div>
                <p className="number_text number_color">{item.number}</p>
              </div>
              <div>
                <p className="waiting_text">
                  {item.text}
                  <span className="waiting_text_span">{item.spanText}</span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Waiting
