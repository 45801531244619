import './App.css'
import FirstBlock from './Modules/FirstBlock/FirstBlock'
import WillGet from './Modules/WillGet/WillGet'
import Author from './Modules/Author/Author'
import Study from './Modules/Study/Study'
import Waiting from './Modules/Waiting/Waiting'
import Reviews from './Modules/Reviews/Reviews'
import Faq from './Modules/Faq/Faq'
import Numbers from './Modules/Numbers/Numbers'
import Companies from './Modules/Companies/Companies'
import Prices from './Modules/Prices/Prices'
import Form from './Modules/Form/Form'


function App() {
  return (
    <div className="App">
      <FirstBlock />
      <WillGet />
      <Waiting />
      <Numbers />
      <Author />
      <Study />
      <Prices />
      <Companies />
      <Reviews />
      <Faq />
      <Form />
    </div>
  )
}

export default App
