import React from 'react'
import styles from './FirstBlock.module.css'
import { logo } from '../../Assets/Images/FirstBlock/FirstBlock'
const CourseBanner = () => {
  return (
    <div className={styles.banner}>
      <div className={`${'customContainer'} ${styles.mobile}`}>
        <div className={styles.header}>
          <img src={logo} alt={'Тарас Радзивіл'} className={styles.logo} />
          <div className={styles.info}>2 місяці навчання</div>
          <div className={styles.info}>Онлайн-формат</div>
          <div className={styles.startDate}>Старт курсу: 29.07.2024</div>
          <a href="#form" className={styles.buttonStart}>
            Записатись на курс
          </a>
        </div>
        <div className={styles.content}>
          <h1 className={styles.title}>
            ОНЛАЙН-КУРС
            <br />
            <span className={styles.title_mod}>ІНВЕСТИЦІЇ В АКЦІЇ З НУЛЯ!</span>
          </h1>
          <p className={styles.subtitle}>
            Створи інвестиційний портфель
            <br />
            <span className={styles.subtitle_mod}>
              на фондовому ринку "з нуля" за 2 місяці.
            </span>
          </p>
        </div>
        <a href="#form" className={styles.button}>
          Записатись на курс
        </a>
      </div>
    </div>
  )
}

export default CourseBanner
